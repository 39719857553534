<!-- 系统配置页面 -->
<template>
  <div class="systemConfig">
    <Tabs v-model="tabVal" :animated="false">
      <TabPane label="七牛云配置" name="1">
        <div class="config-content">
          <Form :model="formTop1" :label-width="130">
            <FormItem label="七牛云映射域名：">
              <Input v-model="formTop1.ossQiniuUrl" style="width:400px"></Input>
            </FormItem>
            <FormItem label="accessKey：">
              <Input v-model="formTop1.ossQiniuAccessKey" style="width:400px"></Input>
            </FormItem>
            <FormItem label="secretKey：">
              <Input v-model="formTop1.ossQiniuSecretKey" style="width:400px"></Input>
            </FormItem>
            <FormItem label="bucket：">
              <Input v-model="formTop1.ossQiniuBucket" style="width:400px"></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(1)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
      <TabPane label="短信配置" name="2">
        <div class="config-content">
          <Form :model="formTop2" :label-width="130">
            <FormItem label="accessKey：">
              <Input v-model="formTop2.accessKeyId" style="width:400px"></Input>
            </FormItem>
            <FormItem label="secretKey：">
              <Input v-model="formTop2.accessKeySecret" style="width:400px"></Input>
            </FormItem>
            <FormItem label="签名名称：">
              <Input v-model="formTop2.smsTitle" style="width:400px"></Input>
            </FormItem>
            <FormItem label="发送模版：">
              <div class="clr">
                <Table class="fl" border :columns="columns" :data="dataList" width="600">
                  <template slot-scope="{ row,index }" slot="signName">
                    <!-- <Input v-model="dataList[index].signName"></Input> -->
                    <Select transfer v-model="dataList[index].msgType">
                      <Option
                        :value="item.value"
                        :label="item.label"
                        v-for="(item,key) in typeList"
                        :key="key"
                      ></Option>
                    </Select>
                  </template>
                  <template slot-scope="{ row,index }" slot="templateCode">
                    <Input v-model="dataList[index].templateCode"></Input>
                  </template>
                </Table>
                <!-- <Button class="fl" style="margin-left:10px" @click="add">新增</Button> -->
              </div>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(2)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>

import { getQNYconfig, editQNYconfig, getDXPZconfig, editDXPZconfig } from '@/api/systemConfiguration.js'

export default {
  data() {
    return {
      tabVal: '1',
      formTop1: {
        ossQiniuUrl: "",
        ossQiniuAccessKey: "",
        ossQiniuSecretKey: "",
        ossQiniuBucket: "",
      },
      formTop2: {
        accessKeyId: "",
        accessKeySecret: "",
        smsTitle: ""
      },
      columns: [
        {
          title: '模版名称',
          key: 'signName',
          slot: 'signName',

        },
        {
          title: '模版号',
          key: 'templateCode',
          slot: 'templateCode',
        },
      ],
      dataList: [
        {
          signName: '',
          msgType: '',
          templateCode: '',
        }
      ],
      typeList: [
        {
          value: '01',
          label: '快速登录验证码'
        },
        {
          value: '02',
          label: '绑定手机号验证码'
        },
        {
          value: '03',
          label: '注册验证码'
        }
      ]
    };
  },
  created() {
    this.getQNYconfig()
    this.getDXPZconfig()
  },
  methods: {
    getQNYconfig() {
      getQNYconfig().then(res => {
        if (res.success) {
          this.formTop1 = res.result
        }
      })
    },
    getDXPZconfig() {
      getDXPZconfig().then(res => {
        if (res.success) {
          this.formTop2 = res.result.smsConfig
          this.dataList = res.result.templateList
        }
      })
    },
    save(type) {
      if (type == 1) {
        editQNYconfig(this.formTop1).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      } else {
        let params = {}
        params.smsConfig = this.formTop2
        params.templateList = this.dataList
        editDXPZconfig(params).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      }

    },
    add() {
      this.dataList.push(
        {
          signName: '',
          msgType: '',
          templateCode: '',
        }
      )
    },
  }
}

</script>
<style lang='scss' scoped>
.systemConfig {
  height: calc(100vh - 150px);
  .config-content {
    height: calc(100vh - 200px);
    padding-top: 10px;
    overflow: auto;
  }
}
</style>